// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingSort__label--tuO5d{font-size:14px}.ListingSort__label--tuO5d>i{margin-right:6px;position:relative;top:1px}.ListingSort__menu--B90Fq{display:flex;flex-direction:column;gap:8px;margin:6px}.ListingSort__menu--B90Fq li.ListingSort__active--i3LG2{font-weight:700}.ListingSort__menu--B90Fq li a{color:inherit}.ListingSort__menu--B90Fq li a:hover{text-decoration:none}", "",{"version":3,"sources":["webpack://./assets/src/js/components/www.iichi.com/pages/shop/ListingSort.vue"],"names":[],"mappings":"AAEA,2BACE,cAAA,CAEA,6BACE,gBAAA,CACA,iBAAA,CACA,OAAA,CAIJ,0BACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,UAAA,CAGE,wDACE,eAAA,CAGF,+BACE,aAAA,CAEA,qCACE,oBAAA","sourcesContent":["@import '@/scss/base/variables';\n\n.label {\n  font-size: 14px;\n\n  > i {\n    margin-right: 6px;\n    position: relative;\n    top: 1px;\n  }\n}\n\n.menu {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin: 6px;\n\n  li {\n    &.active {\n      font-weight: bold;\n    }\n\n    a {\n      color: inherit;\n\n      &:hover {\n        text-decoration: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "ListingSort__label--tuO5d",
	"menu": "ListingSort__menu--B90Fq",
	"active": "ListingSort__active--i3LG2"
};
module.exports = ___CSS_LOADER_EXPORT___;
