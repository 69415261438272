// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShopInfoButton__button--MZH8d{border:1px solid #dadada;border-radius:50%;color:#4b4b4b;font-size:32px;position:relative;top:2px}.ShopInfoButton__button--MZH8d:hover{opacity:.8}.ShopInfoButton__statementForNote--g9sKE{margin-bottom:10px}.ShopInfoButton__statementForNote--g9sKE a{color:inherit;text-decoration:underline}.ShopInfoButton__statementForNote--g9sKE a:hover{text-decoration:none}.ShopInfoButton__openingDate--UL3hM{color:#b9b9b9}", "",{"version":3,"sources":["webpack://./assets/src/js/components/www.iichi.com/pages/shop/ShopInfoButton.vue"],"names":[],"mappings":"AAEA,+BACE,wBAAA,CACA,iBAAA,CACA,aAAA,CACA,cAAA,CACA,iBAAA,CACA,OAAA,CAEA,qCACE,UAAA,CAIJ,yCACE,kBAAA,CAEA,2CACE,aAAA,CACA,yBAAA,CAEA,iDACE,oBAAA,CAKN,oCACE,aAAA","sourcesContent":["@import '@/scss/base/variables';\n\n.button {\n  border: 1px solid $gray300;\n  border-radius: 50%;\n  color: #4b4b4b;\n  font-size: 32px;\n  position: relative;\n  top: 2px;\n\n  &:hover {\n    opacity: 0.8;\n  }\n}\n\n.statementForNote {\n  margin-bottom: 10px;\n\n  a {\n    color: inherit;\n    text-decoration: underline;\n\n    &:hover {\n      text-decoration: none;\n    }\n  }\n}\n\n.openingDate {\n  color: #b9b9b9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ShopInfoButton__button--MZH8d",
	"statementForNote": "ShopInfoButton__statementForNote--g9sKE",
	"openingDate": "ShopInfoButton__openingDate--UL3hM"
};
module.exports = ___CSS_LOADER_EXPORT___;
