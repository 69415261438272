// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShopLinksOther__button--K3zHi:hover{text-decoration:underline}", "",{"version":3,"sources":["webpack://./assets/src/js/components/www.iichi.com/pages/shop/ShopLinksOther.vue"],"names":[],"mappings":"AAEA,qCACE,yBAAA","sourcesContent":["@import '@/scss/base/variables';\n\n.button:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ShopLinksOther__button--K3zHi"
};
module.exports = ___CSS_LOADER_EXPORT___;
